.tree-lighting-container {
    display: flex;
    flex-wrap: wrap;
}
.tree-lighting-title {
    width: 100%;
    flex:100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Lora';
    font-weight: bold;
    color: #8b0000;
    margin-top: 3vw;
    margin-bottom: 1vw;
}
.tree-lighting-text
{
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    margin-left: 25vw;
    margin-right: 25vw;
    margin-bottom: 5vw;
}
.tree-lighting-image
{
  display: block;
  margin: auto;
}