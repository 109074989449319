.service-container {
    display: flex;
    flex-wrap: wrap;
}
.service-title {
    width: 100%;
    flex:100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Lora';
    font-weight: bold;
    color: #8b0000;
    margin-top: 3vw;
    margin-bottom: 1vw;
  }

.service-subtitle
{
    text-align: center;
    font-size: 18px;
    line-height: 150%;
    margin-left: 25vw;
    margin-right: 25vw;
}

.service-subcontainer
{
    margin-top: 3vw;
    margin-left: 2vw;
    margin-right: 1vw;
    margin-bottom: 3vw;
    width: 30%;
}

.service-subcontainer-title
{
    text-align: center;
    font-size: 24px;
    color: #8b0000;
    font-weight: 600;
}

.service-subcontainer-text
{
    margin-top: 2vw;
    line-height: 150%
}

ul > li {
    margin-left: 10px;
}