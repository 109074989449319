@import url('https://fonts.googleapis.com/css?family=Lora');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Lora';
  overflow-y: scroll;
  overflow-x: scroll;
  z-index: -999;
  align-self: flex-start
}

h1 {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

h4 {
  color: #adadad;
  font-size: 14px;
}

p {
  font-family: 'Lora';
}

.header-container {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.07), 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  color: white;
  background-color: #8b0000;
  width: 100%;
  display: flex;
  /* backdrop-filter: blur(5px); */
  position: sticky;
  top: 0;
  z-index: 999;
}

.title-area {
  display: flex;
  align-items: center;
  padding: 1vw;
}

.nav-area {
  margin-top:2vw;
  margin-left:14vw;
  display: flex;
  flex-direction:row;
  z-index: 999;
}

.logo {
  color: inherit;
  width: 4vw;
  margin-left: 1vw;
}

.logo-title {
  font-size: 10px;
  width: 30vw;
  padding: 1vw;
}

.menus {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  list-style: none;
  font-family: 'Lora';
}

.menu-items {
  position: relative;
  font-size: 16px;
  z-index: 999;
}

.menu-items a {
  display: block;
  font-size: inherit;
  color: inherit;
  text-decoration: none;
  font-family: 'Lora';
  position: relative;
  z-index: 999;
}

.menu-items button {
  display: flex;
  align-items: center;
  color: inherit;
  font-size: inherit;
  border: none;
  background-color: transparent;
  cursor: pointer;
  width: 100%;
  font-family: 'Lora';
}

.menu-items > a, .menu-items button {
  text-align: left;
  padding: 0.7rem 1rem;
  width: 100%;
}

.menu-items a:hover,
.menu-items button:hover {
  background-color: #fda6a6;
  z-index: 999;
}

.arrow::after {
  content: "";
  display: inline-block;
  margin-left: 0.28em;
  vertical-align: 0.09em;
  border-top: 0.42em solid;
  border-right: 0.32em solid transparent;
  border-left: 0.32em solid transparent;
}

.dropdown {
  position: absolute;
  right: 0;
  left: auto;
  box-shadow: 0 10px 15px -3px rgba(46, 41, 51, 0.08),
    0 4px 6px -2px rgba(71, 63, 79, 0.16);
  font-size: 0.875rem;
  z-index: 999;
  min-width: 10rem;
  padding: 0.5rem 0.5rem 0.5rem 0rem;
  list-style: none;
  background-color: #fff;
  border-radius: 0.5rem;
  display: none;
  color:black;
}

.dropdown.show {
  display: block;
  z-index: 999;
}

.dropdown .dropdown-submenu {
  position: absolute;
  left: 100%;
  top: -7px;
  z-index: 999;
}
.content {
  margin: 0 auto;
}

.content h2 {
  margin-bottom: 1rem;
}

.content a {
  color: #cc3852;
}
