.contact-title
{
  color: #8b0000;
  font-size: 36px;
  margin-top: 3vw;
  font-weight: 600;
  text-align: center;
  margin-bottom: 3vw;
}

.contact-text
{
  text-align: center;
}