@import url('https://fonts.googleapis.com/css?family=Lora');

.initiative-section-title
{
  text-align: center;
  font-size: 36px;
  margin-top: 3vw;
  color: #8b0000;
  font-weight: 600;
}

.initiative-text
{
  display: flex;
  margin-top: 50px;
  margin-right: 100px;
  margin-left: 100px;
  justify-content: center;

}

.initiative-subsection
{
  padding-left: 20px;
  padding-right: 20px;
  width: 30%;
}

.initiative-subsection-title
{
  font-size: 20px;
  text-align: center;
  color: #8b0000;
}

.initiative-subsection-text
{
  font-size: 14px;
  text-align: justify;
  margin-top: 30px;
}

.break {
  flex-basis: 100%;
  height: 0;
}
