.events-container {
    display: flex;
    flex-wrap: wrap;
}
.events-title {
    width: 100%;
    flex:100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Lora';
    font-weight: bold;
    color: #8b0000;
    margin-top: 3vw;
    margin-bottom: 1vw;
}
.events-subcontainer {
    width: 100%;
}
.events-subcontainer-title
{
    width: 100%;
    flex:100%;
    font-size: 24px;
    font-family: 'Lora';
    color: #8b0000;
    margin-top: 2vw;
    margin-bottom: 2vw;
    margin-left: 2vw;
}
.events-text
{
    text-align: center;
    font-size: 16px;
    line-height: 150%;
    margin-left: 25vw;
    margin-right: 25vw;
    margin-bottom: 5vw;
}
.cny-image
{
    width: 30vw;
    margin: 5px;
}
.cny-image-vertical
{
    width: 18vw;
    margin: 5px;
}
.cny-past-events-text
{
    font-size: 18px;
    line-height: 150%;
    margin-left: 2vw;
    margin-bottom: 2vw;
    text-align: left;
}
.link
{
    margin-left: 5px;
    margin-right: 5px;
}