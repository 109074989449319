.involvement-container {
    display: flex;
    justify-content: center;
}
.involvement-title
{
  width: 100%;
  flex:100%;
  text-align: center;
  font-size: 36px;
  color: #8b0000;
  font-weight: 600;
  margin-top: 3vw;
  margin-bottom: 1vw;
}

.involvement-subcontainer-image-slider
{
    position: relative;
    margin-top: 2vw;
    margin-left: 35vw;
    margin-bottom: 2vw;
}

.involvement-subtitle
{
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    margin-left: 30vw;
    margin-right: 30vw;
}

.involvement-subcontainer
{
    margin-top: 3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    margin-bottom: 5vw;
    width: 30%;
}

.involvement-subcontainer-title
{
    text-align: center;
    font-size: 20px;
    color: #8b0000;
}

.involvement-subcontainer-text
{
    margin-top: 2vw;
    line-height: 150%
}

.display-linebreak {
    white-space: pre-line;
}