.outreach-container {
    display: flex;
    justify-content: center;
}
.outreach-title {
    width: 100%;
    flex:100%;
    text-align: center;
    font-size: 36px;
    font-family: 'Lora';
    font-weight: bold;
    color: #8b0000;
    margin-top: 3vw;
    margin-bottom: 1vw;
  }

.outreach-subtitle
{
    text-align: center;
    font-size: 14px;
    line-height: 150%;
    margin-left: 25vw;
    margin-right: 25vw;
}

.outreach-subcontainer
{
    margin-top: 3vw;
    margin-left: 1vw;
    margin-right: 1vw;
    width: 30%;
}

.outreach-subcontainer-title
{
    text-align: center;
    font-size: 20px;
    color: #8b0000;
}

.outreach-subcontainer-text
{
    margin-top: 2vw;
    line-height: 150%
}

.paintbrush-icon
{
    text-align: center;
    color: #8b0000;
    width: 100%;
    height: 15%;
    margin-bottom: 2vw;
}

.fa-language
{
    text-align: center;
    color: #8b0000;
    width: 100%;
    height: 15%;
    margin-bottom: 2vw;
}

.fa-handshake
{
    text-align: center;
    color: #8b0000;
    width: 100%;
    height: 15%;
    margin-bottom: 2vw;
}