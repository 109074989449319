@import url('https://fonts.googleapis.com/css?family=Lora');

.home-container {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    margin-top: 50px;
  }
.home-activity-subcontainer
  {
    width: 40%;
    margin-left: 10vw;
  }
  
.home-activity-subcontainer-text
{
    font-family: 'Lora';
    text-align: left;
    width: 80%;
    margin-bottom: 50px;
    line-height: 150%;
}

.home-section-title
{
  font-family: 'Lora';
  font-size: 36px;
  color: #8b0000;
  font-weight: 600;
  text-align: left;
}

.home-subsection-title
{
  font-family: 'Lora';
  font-size: 12px;
  color: gray;
  text-align: left;
  padding-bottom: 30px;
}
.home-about-subcontainer
{
    background-color: #ffeded;
    width: 100%;
    margin-top: 75px;
    padding-top: 30px;
    align-items: center;
    padding-bottom: 50px;
}
.home-about-title
{
  font-family: 'Lora';
  font-size: 24px;
  font-weight: 600;
  color: #8b0000;
  text-align: center;
  width: 100%;
  flex-basis: 100%;
  margin-top: 10px;
}

.home-about-text-subcontainer
{
  display: flex;
  width: 60%;
  text-align: center;
  margin-left: 20vw;
  margin-top: 30px;
  line-height: 150%;
  font-family: 'Lora';
  font-size: 16px;
}

.break 
{
  width: 100%;
  flex-basis: 100%;
  height: 0;
}